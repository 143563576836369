import consumer from "./consumer"

consumer.subscriptions.create("RequestChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const newContent = `
      <a data-turbolinks="false" href="/requests/${data.request.uuid}">
        <div class="balloon">
        <div class="balloon1">
          <strong>
            <i class="fas fa-camera"></i>
            New Request!
            </strong>
        </div>
        </div>
      </a>
    `;
    $("#new_requests_content").append(newContent);  
  }
});